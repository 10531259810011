import { Component } from '@angular/core';

@Component({
  selector: 'app-ups-lander',
  templateUrl: './ups-lander.component.html',
  styleUrls: ['./ups-lander.component.scss']
})
export class UpsLanderComponent {

  constructor() { }

}
