<!-- Taradel -->
<section class="taradel-background">
	<div class="container">
		<div class="row" style="padding-top: 50px">
			<div class="col-12 text-left">
				<img src="/assets/images/static-content/ups-lander/ups-taradel.png">
			</div>
		</div>
		<div class="row" style="margin-top: 30px">
			<div class="col-sm-6" style="padding-top: 30px">
				<h2 style="text-transform: none; letter-spacing: 2px;font-size: 1.7em; font-weight: bold;line-height:inherit">
					Need to reach new customers?
				</h2>
				<p style="margin-top: 30px;font-size:20px">
					Taradel's self-serve marketing platform makes it easy to create
					B2C and B2B marketing campaigns with targeted direct mail and digital ads in just minutes.
				</p>
				<ul style="list-style:none;padding-left:0px;margin-top:30px">
					<li style="margin-top:15px">
						<span><img src="/assets/images/static-content/ups-lander/checkmark.svg"></span>&nbsp;&nbsp;Target B2C and B2B prospects
					</li>
					<li style="margin-top:15px">
						<span><img src="/assets/images/static-content/ups-lander/checkmark.svg"></span>&nbsp;&nbsp;Create, launch, and track direct mail and digital advertising campaigns
					</li>
					<li style="margin-top:15px">
						<span><img src="/assets/images/static-content/ups-lander/checkmark.svg"></span>&nbsp;&nbsp;Do everything online in minutes — no hassles, no paperwork
					</li>
					<li style="margin-top:15px">
						<span><img src="/assets/images/static-content/ups-lander/checkmark.svg"></span>&nbsp;&nbsp;Exclusive discounts for <strong>UPS Digital Connections</strong> customers
					</li>
				</ul>
				<div class="row">
					<div class="col-sm-10 text-center">
						<a href="https://www.taradelups.com" class="btn btn-success btn-block" style="height:100px;font-size:36px;padding-top:25px">
							free prospect search!
						</a>
						<br>
						Or, call <a style="color:inherit;font-weight:bold" href="tel:18889109068">1-888-910-9068</a>
					</div>
				</div>

			</div>
			<div class="col-sm-6">
				<img style="max-width:100%" src="/assets/images/static-content/ups-lander/customers.png">
			</div>
		</div>
		<div>
		</div>
	</div>
</section>

<!-- Equifax -->
<div class="row">
	<div class="col-sm-6 equifax-background">
	</div>
	<div class="col-sm-6" style="background-color: #0630ce; padding-top:75px;padding-bottom:75px;color: white;padding-left:75px;padding-right:75px;">
		<img src="/assets/images/static-content/ups-lander/powered_by_equifax.png">
		<br>
		<h2 style="margin-top: 30px;text-transform: none; letter-spacing: 2px;font-size: 1.7em; font-weight: bold;color:white;line-height:inherit">
			An all-in-one marketing platform powered by world-class data
		</h2>
		<p style="margin-top:30px">
			With Taradel, UPS customer can create multichannel marketing campaign in minutes.
			Our easy, online tools help advertisers create the prospect list — and advertise
			across the most popular and effective media channels
		</p>
		<p>
			Search filters include:
		</p>
		<div class="row">
			<div class="col-6">
				<ul>
					<li>
						Industry
					</li>
					<li>Company Size</li>
					<li>
						Number of employees
					</li>
					<li>
						Demographics
					</li>
					<li>
						Home ownership
					</li>
				</ul>
			</div>
			<div class="col-6">
				<ul>
					<li>
						Job title
					</li>
					<li>
						Annual Sales
					</li>
					<li>
						Geographic location
					</li>
					<li>
						Behaviors &amp; interests
					</li>
					<li>Credit &amp; finances</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<!-- Brands -->
<section class="brands-background">
	<div class="container">
		<div class="row text-center" style="padding-top: 100px">
			<div class="col-12">
				<h1 style="color:white;font-size:36px">
					Trusted by 20,000+ brands and businesses
				</h1>
				<img style="margin-top:40px" src="/assets/images/static-content/ups-lander/partner_logos.png">
			</div>
		</div>
	</div>
</section>
