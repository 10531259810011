import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-usps-lander',
	templateUrl: './usps-lander.component.html',
	styleUrls: ['./usps-lander.component.scss']
})
export class UspsLanderComponent implements OnInit {

	constructor(private router: Router,
		private cookieService: CookieService) { }

	ngOnInit(): void {
		this.cookieService.set('RefSource', 'USPS EDDM Page', 90);
		this.router.navigate(['/']);
	}
}
